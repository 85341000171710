import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { templateConfigMap, TemplateKey } from './template.configuration';
import { UIMesageService } from '../../../shared/services/ui-message.service';
import defaultOptionsEn from '../../../../assets/locale/en/default-options.en.json';
import defaultOptionsDe from '../../../../assets/locale/de/default-options.de.json';
import defaultOptionsIt from '../../../../assets/locale/it/default-options.it.json';
import defaultOptionsFr from '../../../../assets/locale/fr/default-options.fr.json';
import defaultOptionsPt from '../../../../assets/locale/pt/default-options.pt.json';
import defaultOptionsEs from '../../../../assets/locale/es/default-options.es.json';

import { HeaderData, OptionConfigClass, OptionList, OptionListPersistence } from 'whocan-lib';
import { EssErrorService, EssTranslationService } from 'ngx-essentia';
import { OptionData } from 'whocan-lib';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
    providedIn: 'root'
})
/**
 *
 */
export class TemplateService {
    private fallbackShowKeys = false
    public templateKey: string = 'advanced'
    private getConfigAttributeCallCounter = 0
    private getDefaultSettingsCallCounter = 0
    public defaultOptions: any = null;

    constructor(
        private logger: LoggerService,
        private trans: EssTranslationService,
        private uiMessage: UIMesageService,
        private errorService: EssErrorService,
        @Inject(LOCALE_ID) private locale: string,
        private transLoco: TranslocoService


    ) {
        this.initDefaultOptions()
    }



    public getTemplateHierarchy(templateKey?: string): string[] {
        if (!templateKey) templateKey = this.templateKey
        return this.getTemplateHierarchyReverse(templateKey).reverse()
    }

    private getTemplateHierarchyReverse(templateKey: string): string[] {
        let returnValue = templateKey ? [templateKey] : []
        const inheritFrom = this.getTemplateConfig(templateKey)?.general.inheritFrom
        if (inheritFrom && inheritFrom !== templateKey) {
            returnValue = [...returnValue, ...this.getTemplateHierarchyReverse(inheritFrom)] // recusrsive call with inherit from
        }
        return (returnValue) // reverse the array to make it easy to pop
    }

    private initDefaultOptions() {
        if (this.locale === 'de') {
            this.defaultOptions = defaultOptionsDe
        } else if (this.locale === 'pt') {
            this.defaultOptions = defaultOptionsPt
        } else if (this.locale === 'it') {
            this.defaultOptions = defaultOptionsIt
        } else if (this.locale === 'fr') {
            this.defaultOptions = defaultOptionsFr
        } else if (this.locale === 'es') {
            this.defaultOptions = defaultOptionsEs
        } else {// the default is english
            this.defaultOptions = defaultOptionsEn
        }
    }

    private getDefaultOptionListData(defaultOptionsKey: string): OptionListPersistence {
        let returnValue = this.defaultOptions[defaultOptionsKey]
        if (!returnValue) {
            returnValue = defaultOptionsEn[defaultOptionsKey];
        }
        return returnValue
    }

    /**
     *
     * @returns a deep copy of the default options
     */
    public getDefaultOptionList(): OptionList {
        let optionsData: OptionData[] | OptionListPersistence;
        const defaultOptionsKey = <string>this.getConfigAttribute('general', 'defaultOptions');
        if (defaultOptionsKey) {
            optionsData = this.getDefaultOptionListData(defaultOptionsKey)
            this.logger.log('defaultOptionsData', optionsData)
        }
        return (new OptionList(optionsData)).deepCopy(); // need the deep copy to avoid changes made to the option would effect the next poll created
    }

    public initTemplate(templateKey: string) {
        // first reset the default
        this.templateKey = templateKey;
    }

    public getTemplateKeys(getAll: boolean = false): string[] {
        const returnValue: string[] = []
        templateConfigMap.forEach((config, key) => {
            if (getAll || config.general.show) {
                returnValue.push(<TemplateKey>key)

            }
        });
        return returnValue;
    }

    public getDefaultSettings(templateKey?: string): HeaderData {
        //      this.logger.log('getConfigAttribute start', attributeKeyLevel1, attributeKeyLevel2, templateKey)
        let templateKeyWork = templateKey;
        if (!templateKeyWork) {
            templateKeyWork = this.templateKey
        }
        if (!templateKeyWork) {
            templateKeyWork = TemplateKey.advanced
            this.logger.log('getDefaultSettings, template not initialized')
        }
        this.getDefaultSettingsCallCounter = 0
        try {
            const returnValue = this.getDefaultSettingsRecursive(templateKeyWork)
            return returnValue

        } catch (error) {
            this.logger.error(error)
            return
        }
    }


    private getDefaultSettingsRecursive(templateKey: string): HeaderData {
        const attributeKeyLevel1 = 'defaultSettings'
        this.getDefaultSettingsCallCounter++
        // make sure we are not in an endless recursive loop
        if (this.getDefaultSettingsCallCounter > 10) {
            this.errorService.newError('Error getting attribute ')

        }
        let returnValue = this.getTemplateConfig(templateKey)[attributeKeyLevel1]
        if (returnValue === undefined) {
            this.logger.log('getConfigAttributeRecursive; undefined', attributeKeyLevel1, templateKey)
            const inheritFrom = this.getTemplateConfig(templateKey)?.general.inheritFrom
            if (inheritFrom) {
                this.logger.log('getConfigAttributeRecursive; inheritFrom', attributeKeyLevel1, templateKey)
                returnValue = this.getDefaultSettingsRecursive(inheritFrom)
            }
            else {
                this.logger.log('getConfigAttributeRecursive; NOT inheritFrom', attributeKeyLevel1, templateKey)
                returnValue = this.getDefaultSettingsRecursive(TemplateKey.advanced)
            }
        }
        return returnValue
    }

    public getConfigAttribute(attributeKeyLevel1: string, attributeKeyLevel2: string, templateKey?: string): string | boolean | string[] {
        //      this.logger.log('getConfigAttribute start', attributeKeyLevel1, attributeKeyLevel2, templateKey)
        let templateKeyWork = templateKey;
        if (!templateKeyWork) {
            templateKeyWork = this.templateKey
        }
        if (!templateKeyWork) {
            templateKeyWork = TemplateKey.advanced
            this.logger.log('getConfigAttribute, template not initialized' + attributeKeyLevel1 + "/" + attributeKeyLevel2)
        }
        this.getConfigAttributeCallCounter = 0
        try {
            const returnValue = this.getConfigAttributeRecursive(attributeKeyLevel1, attributeKeyLevel2, templateKeyWork)
            return returnValue

        } catch (error) {
            this.logger.error(error)
            return ('')
        }
    }

    //      in order to acces translation not asynchronously
    public async loadTransLationFiles() {
        const templateKeys = this.getTemplateKeys(true);
        const loadPromises = templateKeys.map(key => this.trans.load('template/' + key));
        await Promise.all(loadPromises);
    }

    public getLabel(attributeKeyLevel1: string, attributeKeyLevel2: string, templateKey?: string) {
        const templateHierarchy = this.getTemplateHierarchy(templateKey)
        const returnValue = this.trans.translate(attributeKeyLevel1 + '.' + attributeKeyLevel2, 'template', templateHierarchy)

        return returnValue
    }


    private getConfigAttributeRecursive(attributeKeyLevel1: string, attributeKeyLevel2: string, templateKey: string): string | boolean {
        this.getConfigAttributeCallCounter++
        // make sure we are not in an endless recursive loop
        if (this.getConfigAttributeCallCounter > 10) {
            const errorMessage = ('Error getting attribute: ' + templateKey + "/" + attributeKeyLevel1 + "/" + attributeKeyLevel2)
            throw (new Error(errorMessage))
        }
        let returnValue = this.getTemplateConfig(templateKey)[attributeKeyLevel1]?.[attributeKeyLevel2]
        if (returnValue === undefined) {
            const inheritFrom = this.getTemplateConfig(templateKey)?.general.inheritFrom
            if (inheritFrom) {
                returnValue = this.getConfigAttributeRecursive(attributeKeyLevel1, attributeKeyLevel2, inheritFrom)
            }
            else {
                this.logger.log('getConfigAttributeRecursive; NOT inheritFrom', attributeKeyLevel1, attributeKeyLevel2, templateKey)
                returnValue = this.getConfigAttributeRecursive(attributeKeyLevel1, attributeKeyLevel2, TemplateKey.advanced)
            }
        }
        return returnValue
    }

    private getTemplateConfig(templateKey: string) {
        let returnValue = templateConfigMap.get(templateKey)
        if (returnValue === undefined || returnValue === null) {
            returnValue = templateConfigMap.get(TemplateKey.advancedNew)
        }
        return returnValue

    }

    /**
     *  make sure the template that we goot is valid and if not set template to advanced
     * @param templateFromRoute
     * @returns
     */
    public getPollTemplate(templateFromRoute: string): string {
        let templateKey: string;
        templateConfigMap.forEach((config, key) => {
            if (key === templateFromRoute) {
                templateKey = <TemplateKey>key;
            }
        })
        if (templateKey) {
            return templateKey
        } else {
            return TemplateKey.advanced
        }
    }

    public getIcon(templateKey?: string): string {
        return <string>this.getConfigAttribute('general', 'icon', templateKey)
    }

    getOptionConfigKeys(filterAllowToAddOptions: boolean = false): string[] {
        let returnValue = []
        let optionTypes = this.getConfigAttribute('options', 'optionTypes')
        if (Array.isArray(optionTypes)) {
            returnValue = <string[]>optionTypes
        }
        if (filterAllowToAddOptions) {
            returnValue = returnValue.filter((optionConfigKey) => {
                const optionConfig = OptionConfigClass.newOptionConfigClass(optionConfigKey)
                if (!optionConfig) throw (this.errorService.newError('getOptionConfigKeys - no such optionConfig' + optionConfigKey))
                return optionConfig.allowToAddOptions
            })
        }
        return returnValue
    }

    getDateOptionConfigKeys(filterAllowToAddOptions: boolean = false): string[] {
        let returnValue = this.getOptionConfigKeys(filterAllowToAddOptions)
        returnValue = returnValue.filter(optionConfigKey => {
            const optionConfig = OptionConfigClass.newOptionConfigClass(optionConfigKey)
            if (!optionConfig) throw (this.errorService.newError('getDateOptionConfigKeys - no such optionConfig' + optionConfigKey))
            return optionConfig.isOfTypeDate
        })
        return returnValue
    }


    getOptionConfigKeyLabel(configKey: string): string {
        let returnValue = this.getLabel('configurableOption', configKey + '_buttonLabel')
        if (!returnValue) {
            this.logger.error('No Button Label for optionType: ' + configKey + '_buttonLabel')
            returnValue = returnValue
        }
        return returnValue
    }
}
