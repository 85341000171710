import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
export interface TestData {
    someText?: string;
    someNumber?: string;
    someArray?: string[]
    someObject?: { someAttribute1?: string }
}

export class TestClass {
    private _data: TestData

    constructor(data?: TestData) {
        this._data = cloneDeep(data)
    }

    get someText() {
        return this._data?.someText
    }
    set someText(value: string) {
        this._data.someText = value;
    }

    get someNumber() {
        return this._data?.someNumber;
    }
    set someNumber(value: string) {
        this._data.someNumber = value;
    }

    get someArray() {
        return this._data?.someArray;
    }
    set someArray(value: string[]) {
        this._data.someArray = value;
    }

    get someObject() {
        return this._data?.someObject;
    }
    set someObject(value: { someAttribute1?: string }) {
        this._data.someObject = value;
    }

    /**
     * Returns a deep clone of the `_data` property.
     *
     * @returns {TestData} A deep clone of the `_data` property.
     */
    get data(): TestData {
        return cloneDeep(this._data)
    }

    cloneDeep(): TestClass {
        return cloneDeep(this)
    }

    isEqual(other: TestClass): boolean {
        return isEqual(this, other)
    }
}
