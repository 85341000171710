import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VoteClass, VoteData } from 'whocan-lib';
import { PathService } from '../../core/path.service';
import { AuthService } from '../../../user/auth.service';

import { CurrentPollService } from '../../core/current-poll.service';
import { VotesService } from './votes.service';
import { OptionsService } from '../../shared/options/options.service';
import { HeaderService } from '../../shared/poll-header/header.service';
import { PollParticipantsService } from '../../shared/poll-header/poll-participants.service';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import isEqual from 'lodash/isEqual';
@Injectable({
    providedIn: 'root'
})
export class UserVoteService {
    userVote: VoteClass;
    public isLoggingOut: boolean = false
    private _userVoteSinceLastSave: VoteClass; /**indicator to verify if ther was a change in user vote since last save  */
    constructor(
        private logger: LoggerService,
        private afd: AngularFireDatabase,
        private pathService: PathService,
        private votesService: VotesService,
        private authService: AuthService,
        private currentPollService: CurrentPollService,
        private optionsService: OptionsService,
        private headerService: HeaderService,
        private pollParticipantsService: PollParticipantsService,
        private googleAnayltics: GoogleAnalyticsService,
    ) {
        this.initUserVote()
    }

    public initUserVote(voteUserId?: string) {
        this.logger.log('initUserVote')
        this.userVote = this.getUserVote(voteUserId)
        this.resetVoteSinceLastSave()
        this.isLoggingOut = false;
    }

    private resetVoteSinceLastSave() {
        this._userVoteSinceLastSave = this.userVote.cloneDeep()
    }

    private getUserVote(voteUserId: string): VoteClass {
        const usersVoteIndex = this.getUsersVoteIndex(voteUserId)
        if (usersVoteIndex > -1) {
            const userVote = this.votesService.voteClassList[usersVoteIndex]
            this.logger.log('getUserVote: user vote exists', userVote)
            // return a copy so the user works on that and all results remain as they were till the user submits the new / changed vote
            return userVote.cloneDeep()
        } else {
            this.logger.log('getUserVote: not user vote')
            const userVoteDataNew: VoteData = {
                userId: this.authService.uid,
                userName: this.authService.displayName,
                userEmail: this.authService.email,
                choicesArray: []
            };
            return new VoteClass(userVoteDataNew, this.optionsService.optionList.lengthIncludingDeleted);
        }
    }


    public async deleteUserVote(ownerId: string, voteId: string, userId: string): Promise<void> {
        this.logger.log('deleteUserVote', ownerId, voteId, userId);
        const ref: AngularFireObject<any> = this.afd.object(this.pathService.getUserVotePath(userId, ownerId, voteId));
        ref.remove();
        this.votesService.voteClassList.forEach((vote, index) => {
            if (vote.userId == userId) this.votesService.voteClassList.splice(index, 1);
        })
    }

    /**
     *
     * @returns -1 if the user has not voted, the index of the users vote otherwise
     */
    public getUsersVoteIndex(uid?: string): number {
        let returnValue = -1;
        const votes = this.votesService.voteClassList;
        if (!uid) uid = this.authService.uid;
        if (!votes) { return returnValue; }
        votes.forEach((vote, index) => {
            if (vote.userId === uid) { returnValue = index; }
        });
        return returnValue;
    }

    get userVoteChoiceInvitationIsNull(): boolean {
        if (this.userVote.choiceInvitation === undefined ||
            this.userVote.choiceInvitation === null ||
            this.userVote.choiceInvitation === 0
        ) {
            return true
        }
        return false
    }

    canDeactivate() {
        return !this.hasUnsavedChanges || this.isLoggingOut
    }

    get hasUnsavedChanges(): boolean {
        return !isEqual(this.userVote, this._userVoteSinceLastSave)
    }

    public async saveVote() {
        this.logger.log('saveVote', this.userVote)
        const length = this.optionsService.optionList.lengthIncludingDeleted;
        this.userVote.initChoiceList(length)
        await this.votesService.persistVote(this.userVote)
        await this.pollParticipantsService.updateParticipants( // @todo should be better a backend function
            this.currentPollService.ownerId,
            this.headerService.headerClass.ownerName,
            this.currentPollService.pollId,
            this.headerService.headerClass.pollTitle,
            this.userVote.userName
        );
        if (
            this.authService.displayName === null ||
            this.authService.displayName === '' ||
            this.authService.isAnonymous
        ) {
            this.authService.setDisplayName(this.userVote.userName);
        }
        this.optionsService.saveOptionsCreatedByParticpant(this.userVote.userName);
        this.googleAnayltics.emitVote(this.headerService.headerClass.pollTemplate, this.headerService.headerClass.theme)
        this.resetVoteSinceLastSave()
    }
}
