import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

/**
 * Represents a generic list item that holds data of type `ItemData`.
 * Should extendend to be used with EssGenericList
 * Provides methods to clone and compare the data.
 *
 * @template ItemData - The type of data held by the list item.
 */
export class EssGenericListItem<ItemData> {
    protected _data: ItemData

    constructor(data: ItemData) {
        this._data = cloneDeep(data)
    }

    /**
     * Returns a deep copy of the data
     */
    get data(): ItemData {
        return cloneDeep(this._data)
    }


    isEqual(other: EssGenericListItem<ItemData>): boolean {
        return isEqual(this, other)
    }

}
