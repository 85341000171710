import { repairMissingIndexes, syncIndex } from "../../helper";
import { TimePeriodClass } from "../time-period.class";
import { OptionTypeEnum } from "./option-type.class";
import { OptionClass, OptionData, OptionPersistence } from "./option.class";
import isEqual from 'lodash/isEqual'

export type OptionListPersistence = OptionPersistence[] | OptionListPersistenceNew | null

export interface OptionListPersistenceNew {
    sortMap: number[];
    optionArray: OptionPersistence[];
}
const optionDataDummy: OptionData = {
    index: 0,
    optionConfigKey: 'dummy',
    isDeleted: true,
    type: OptionTypeEnum.text
}
/**
 * Class to handle with collection of options for a poll
 * It supports two kind of indezies:
 * 1.) the option-index which should never be changed because the votes are linked via this index
 * 2.) the sort-index that is the sort order in which  options are displayed
 * forEach and the iterator are considering the sort-order and exlcuding option that are marked as deleted
 * to access options in *ngFor us .orderOptions
 * To persist options use the optionData of OptionClass
 */
export class OptionList implements Iterable<OptionClass> {
    private _optionClassArray: OptionClass[] = [];
    private _optionsSinceLastSave: OptionClass[] = [];
    private _islegacyData: boolean = false /**indicates if  sortmap should persist  and options persist as optionArray */
    private _sortMap: number[] = [];
    private _sortMapSinceLastSave: number[] = [];

    constructor(
        data: OptionListPersistence
    ) {
        let optionPersistanceArray: OptionPersistence[] = []
        if (data) {// @todo: make this more beautifull
            if (OptionList.isOfTypeNew(data)) { // due to legacy data we have to distingush these cases
                optionPersistanceArray = (<OptionListPersistenceNew>data).optionArray;
                if ((<OptionListPersistenceNew>data).sortMap) this._sortMap = (<OptionListPersistenceNew>data).sortMap;
            } else {// legacy
                optionPersistanceArray = (<OptionPersistence[]>data);
                this._islegacyData = true;
            }
            const isArrayCorrupt = repairMissingIndexes(optionPersistanceArray, optionDataDummy)
            const isIndexCorrupt = syncIndex(optionPersistanceArray, 'index')
            this.populateOptionClassArray(optionPersistanceArray);
            if (this.isSortMapCorrupt || isArrayCorrupt || isIndexCorrupt) {
                this.resetSortMap()
            }
            this.resetLastSave()
        }
    }

    private get isSortMapCorrupt(): boolean {
        if (!this._sortMap) return true
        if (this._sortMap.length == 0) return true;
        if (this._sortMap.length !== this.numberOfNotDeletedOptions) return true
        return false
    }

    private get numberOfNotDeletedOptions(): number {
        let returnValue = this._optionClassArray.length
        this._optionClassArray.forEach(option => {
            if (option.isDeleted) returnValue--
        })
        return returnValue
    }

    private populateOptionClassArray(optionDataArray: OptionPersistence[]) {
        optionDataArray?.forEach((optionPersistence, index) => {
            this._optionClassArray.push(new OptionClass(optionPersistence));
        });
    }

    /**
     *
     * @returns a new option list with a deep copy of the options
     * keeps the order of the sortmap
     * options that are marked as deleted are not copied
     */

    deepCopy(): OptionList {
        const returnValue = new OptionList(null)
        this.forEach((option, index) => {
            const optionCoopy = option.deepCopy()
            returnValue.add(optionCoopy, index)

        })
        return returnValue
    }

    static isOfTypeNew(data: OptionData[] | OptionListPersistenceNew) {
        if ((<OptionListPersistenceNew>data).optionArray) {
            return true;
        } else {
            return false;
        }
    }

    public uninitialize() {
        this._optionClassArray = [];
        this._optionsSinceLastSave = [];
        this._sortMapSinceLastSave = [];
        this._islegacyData = false
        this._sortMap = [];
    }

    /**
     *  excluding deleted option use this to iterate in *ngFor
    */
    get orderedOptions(): OptionClass[] {
        const returnValue: OptionClass[] = []
        this.forEach(optionClass => {
            returnValue.push(optionClass)
        })
        return returnValue
    }

    get isLegacyData(): boolean {
        return this._islegacyData
    }

    get sortMap(): number[] {
        return this._sortMap
    }

    private resetSortMap() {
        const optionsArrayCopy = [...this._optionClassArray]
        optionsArrayCopy.sort(OptionClass.standardCompare)
        this._sortMap = []
        optionsArrayCopy.forEach((option) => {
            if (!option.isDeleted) {
                this._sortMap.push(option.index)
            }
        })
    }

    /**
     * considering only NOT deleted options
     */
    get length(): number {
        let returnValue = 0
        this.forEach(value => {
            returnValue++
        })
        return returnValue
    }


    get lengthIncludingDeleted(): number {
        return this._optionClassArray.length
    }

    get firstCheckedOption(): OptionClass {
        return this.orderedOptions.find(
            (option) => option.checked === true
        );
    }

    /**loop in the sort order and jump over deleted options */
    forEach(callback: (option: OptionClass, index: number) => void) {
        if (!this._sortMap) return
        for (let i = 0; i < this._sortMap.length; i++) {
            const optionClass = this._optionClassArray[this._sortMap[i]]
            if (!optionClass) {
                //                console.error('OptionList.forEach no such option ', this)
            } else {
                if (optionClass && !optionClass.isDeleted) {// the deletion check is not required because deleted option should not be in the sortMap
                    callback(optionClass, i);
                }
            }
        }
    }

    /**loop in the option-index order including deleted options */
    forEachIncludingDeleted(callback: (option: OptionClass, index: number) => void) {
        for (let i = 0; i < this._optionClassArray.length; i++) {
            const optionClass = this._optionClassArray[i]
            {
                callback(optionClass, i);
            }
        }
    }

    /** loop in the sort order and jump over deleted options */
    [Symbol.iterator](): Iterator<OptionClass> {
        let index = 0;
        return {
            next: () => {
                for (let i = 0; i < this._sortMap.length; i++) {
                    const optionClass = this._optionClassArray[this._sortMap[i]]
                    if (optionClass && !optionClass.isDeleted) {
                        return { value: optionClass, done: false };
                    }
                }
                return { done: true, value: null };
            }
        };
    }

    public markCheckedOptionsAsDeleted() {
        const checkedOptions: OptionClass[] = []
        this.forEach((option) => {
            if (option.checked === true) {
                checkedOptions.push(option) // the deletion manupulates the list; so we need to collect first the checked options
            }
        });
        checkedOptions.forEach((option) => {
            this.markOptionAsDeleted(option.index)
        })
    }

    public markOptionAsDeleted(optionIndex: number) {
        const option = this.getByOptionIndex(optionIndex)
        if (option) {
            option.isDeleted = true
            this.removeFromSortMap(option.index)
        }

    }

    private removeFromSortMap(optionIndex: number) {
        const indexInSortMap = this._sortMap.indexOf(optionIndex)
        if (indexInSortMap !== -1) {
            this._sortMap.splice(indexInSortMap, 1)
        }
    }

    /**
     *  * adds an option to the list and puts it in the right sort order
     * @param optionClass
     * @param sortIndextToBeAddedAt the option will be added at  indextToBeAddedAt
     * if left empty
     *      a date-option will be added behind the biggest date that is smaller than the date option
     *      a text option will be added right behind the last text opiton
     *
     */
    public add(optionClass: OptionClass, sortIndextToBeAddedAt?: number) {
        optionClass.index = this._optionClassArray.length // make sure the optionIndex is correct
        this._optionClassArray.push(optionClass)
        if (sortIndextToBeAddedAt === undefined) {
            sortIndextToBeAddedAt = this.getIndexToBeAddedAt(optionClass);
        }
        if (sortIndextToBeAddedAt > this.sortMap.length) {
            throw new Error("add option to optionList: indextToBeAddedAt > this.sortMap.length " + sortIndextToBeAddedAt + ", " + this.sortMap.length)
        }
        this.addToSortMap(optionClass, sortIndextToBeAddedAt)
    }

    private addToSortMap(optionClass: OptionClass, indexToBeAddedAt: number) {
        if (indexToBeAddedAt > this._sortMap.length + 1) { throw new Error('addToSortMap indextToBeAddedAt > this._sortMap.length + 1') }
        const sortMapWithoutElement = this._sortMap
        const newSortMap = sortMapWithoutElement.slice(0, indexToBeAddedAt)
            .concat([optionClass.index])
            .concat(sortMapWithoutElement.slice(indexToBeAddedAt));
        this._sortMap = newSortMap
    }

    public handlePositionChange(option: OptionClass, changedOption: OptionClass) {
        if (!option.timePeriodClass.isStartDateTimeEqual(changedOption.timePeriodClass)) {
            this.moveDateOptionToStandardPosition(changedOption)
        }
    }

    /**
     * Doesn't do anything if its an option of type 'Text'
     * @param optionClass
     */
    public moveDateOptionToStandardPosition(option: OptionClass) {
        if (option.isOfQuestionTypeDate && !this.isParentInList) {
            const indexToBeMovedAt = this.getIndexToBeMovedAt(option)
            this.move(option, indexToBeMovedAt)
        }
    }

    private hasChildOptions(option: OptionClass) {
        console.warn('hasChildOptions X', option)
        if (!option.optionConfig.isParent) return false;
        // get next option and check if that is a parent
        const nextOption = this.getNextOption(option)
        if (!nextOption) {
            console.warn('hasChildOptions !nextOptions')
            return false
        }
        if (nextOption.optionConfig.isParent) {
            console.warn('hasChildOptions false')
            return false
        } else {
            console.warn('hasChildOptions true')
            return true
        }
    }

    private getIsChild(option: OptionClass): boolean {
        const parent = this.getParentOption(option)
        if (parent) return true
        return false
    }

    private move(optionClass: OptionClass, indexToBeMovedAt: number) {
        if (indexToBeMovedAt > this._sortMap.length + 1) { throw new Error('moveOption indextToBeAddedAt > this._sortMap.length + 1') }
        const oldSortIndex = this.getSortIndex(optionClass.index)
        const firstPiece = this.sortMap.slice(0, oldSortIndex)
        const secondPiece = this.sortMap.slice(oldSortIndex + 1)
        const sortMapWithoutElement = firstPiece.concat(secondPiece)
        const newSortMap = sortMapWithoutElement.slice(0, indexToBeMovedAt)
            .concat([optionClass.index])
            .concat(sortMapWithoutElement.slice(indexToBeMovedAt));
        this._sortMap = newSortMap
    }

    private getIndexToBeMovedAt(optionClass: OptionClass) {
        let indextToBeMovedAt: number
        const oldSortIndex = this.getSortIndex(optionClass.index)
        if (optionClass.isOfQuestionTypeDate) {
            const latestOptionBefore = this.getLatestOptionBeforeDate(optionClass.timePeriodClass.startDateTime);
            if (latestOptionBefore) {
                indextToBeMovedAt = this.getSortIndex(latestOptionBefore.index)
                if (indextToBeMovedAt < oldSortIndex) {
                    indextToBeMovedAt = indextToBeMovedAt + 1
                }
            } else {
                indextToBeMovedAt = 0;
            }
        }
        else { // its text then
            const lastTextOption = this.lastTextOption;
            if (lastTextOption) {
                indextToBeMovedAt = this.getSortIndex(lastTextOption.index) + 1;
            } else {
                indextToBeMovedAt = this.sortMap.length - 1;
            }
        }
        return indextToBeMovedAt;
    }


    private getIndexToBeAddedAt(optionClass: OptionClass) {
        let indextToBeAddedAt: number
        if (optionClass.isOfQuestionTypeDate) {
            const latestOptionBefore = this.getLatestOptionBeforeDate(optionClass.timePeriodClass.startDateTime);
            if (latestOptionBefore) {
                indextToBeAddedAt = this.getSortIndex(latestOptionBefore.index) + 1;
            } else {
                indextToBeAddedAt = 0;
            }
        } else { // its text then
            const lastTextOption = this.lastTextOption;
            if (lastTextOption) {
                indextToBeAddedAt = this.getSortIndex(lastTextOption.index) + 1;
            } else {
                indextToBeAddedAt = this.sortMap.length;
            }
        }
        return indextToBeAddedAt;
    }

    public getBySortIndex(sortIndex: number): OptionClass {
        // return this.orderedOptions[sortIndex] // wrong becanuse the excludes the deleted optioons
        const optionIndex = this.getOptionIndex(sortIndex)
        return this._optionClassArray[optionIndex]
    }

    public getByOptionIndex(optionIndex: number): OptionClass {
        return this._optionClassArray[optionIndex]
    }

    getSortIndex(optionIndex: number): number {
        for (let i = 0; i < this._sortMap.length; i++) {
            if (this._sortMap[i] === optionIndex) { return i }
        }
        throw (new Error('getSortIndex no such option in sortMap ' + optionIndex + "; " + JSON.stringify(this._optionClassArray) + "; " + JSON.stringify(this._sortMap)))
    }

    getOptionIndex(sortIndex: number): number {
        if (this._sortMap[sortIndex] === null) throw (new Error('!this._sortMap[sortIndex]'))
        return this._sortMap[sortIndex]
    }

    get dateOptions(): OptionClass[] {
        const returnValue: OptionClass[] = []
        this.forEach(option => {
            if (option.isOfQuestionTypeDate) {
                returnValue.push(option)
            }
        })
        return returnValue
    }

    get textOptions(): OptionClass[] {
        const returnValue: OptionClass[] = []
        this.forEach(option => {
            if (option.isOfQuestionTypeText) {
                returnValue.push(option)
            }
        })
        return returnValue
    }

    /**
     *
     * @param option should be in the OptionList
     * @returns the next non-deleted option in the sort order - null if ther is none
     */

    getNextOption(option: OptionClass): OptionClass {
        // Start from the next option and find the first non-deleted option
        for (let sortIndex = this.getSortIndex(option.index) + 1; sortIndex < this.sortMap.length; sortIndex++) {
            const nextOption = this.getBySortIndex(sortIndex)
            if (!nextOption.isDeleted) {
                return nextOption;
            }
        }
        return null;
    }

    /**
     *
     * @param option that must be of this list
     * @returns true if option.index is the last optionIndex in sortMap that is not marked isDeleted
     */
    isLast(option: OptionClass): boolean {
        const lastOption = this.lastOption
        if (lastOption === null) return false // 'There is no non-deleted option in this list so its not the last
        return this.lastOption.index === option.index
    }

    /**
     * get the last non deleted option
     */
    get lastOption(): OptionClass {
        let returnValue: OptionClass = null;
        this.forEach(option => { // for each is iterating in sort order and jumps deleted options
            returnValue = option
        })
        return returnValue
    }

    /**
     *
     * @returns null if there is no text opption
     */
    get lastTextOption(): OptionClass {
        let returnValue: OptionClass = null;
        this.forEach(option => { // for each is iterating in sort order and jumps deleted options
            if (option.isOfQuestionTypeText) {
                returnValue = option
            }
        })
        return returnValue
    }

    /**
    *
    * @returns null if there is no date option
    */
    get lastDateOption(): OptionClass {
        let returnValue: OptionClass = null;
        this.forEach(option => { // for each is iterating in sort order and jumps deleted options
            if (option.isOfQuestionTypeDate) {
                if (!returnValue ||
                    returnValue.timePeriodClass.startDateTime < option.timePeriodClass.startDateTime
                ) {
                    returnValue = option
                }
            }
        })
        return returnValue
    }

    /**
    * @returns returns the latest date of all dates in OptionList
    */
    get latestDateOption(): OptionClass {
        let returnValue: OptionClass = null;
        this.forEach(option => { // forEach is iterating in sort order and jumps deleted options
            if (option.isOfQuestionTypeDate) {
                if (returnValue === null || option.timePeriodClass.startDateTime > returnValue.timePeriodClass.startDateTime) {
                    returnValue = option
                }
            }
        })
        return returnValue
    }

    resetLastSave() {
        this.resetSortMapLastSave()
        this.resetOptionsLastSave()
    }

    resetOptionsLastSave() {
        this._optionsSinceLastSave = []
        this._optionClassArray.forEach(option => {
            this._optionsSinceLastSave.push(option.deepCopy())
        })
    }

    resetLastSaveForOneOption(option: OptionClass) {
        const optionIndex = option.index
        this._optionsSinceLastSave[optionIndex] = option.deepCopy()

    }

    resetSortMapLastSave() {
        if (this._sortMap) {
            this._sortMapSinceLastSave = [...this._sortMap]
        }
    }

    get sortMapHasChangedSinceLastSave(): boolean {
        return !isEqual(this._sortMap, this._sortMapSinceLastSave);
    }

    public optionHasChangedSinceLastSave(optionIndex: number): boolean {
        if (this._optionClassArray[optionIndex] && this._optionsSinceLastSave[optionIndex]) {
            return !isEqual(this._optionClassArray[optionIndex], this._optionsSinceLastSave[optionIndex]);
        } else {
            return true;
        }
    }



    /**
       * @returns new timeperiod object with the date and time depending on what was added to the list before
       * date (if not provided) is set to the date of the last date option
       * time is taken from the date option  and if it's the same date we add on hour
       *
       */
    public getTimePeriodForNewOption(date?): TimePeriodClass {
        if (this.dateOptions.length === 0) {
            return new TimePeriodClass(date)
        }
        let addOneHour = true;
        {// first fix the date
            if (!date) {
                addOneHour = false; // if there is no date provided take the same date that was added before
                date = this.lastDateOption?.timePeriodClass.startDateTime;
            }
        }
        {// fix the time
            let startTimeString: string;
            let endTimeString: string;
            const lastOptionOfDate = this.getLatestOptionOfSameDate(date);
            if (lastOptionOfDate) {
                // if yes: get the last option of the same date and add on hour
                startTimeString = lastOptionOfDate.timePeriodClass.startTimeString;
                endTimeString = lastOptionOfDate.timePeriodClass.endTimeString
                if (addOneHour) {
                    startTimeString = TimePeriodClass.addHour(startTimeString)
                    endTimeString = TimePeriodClass.addHour(endTimeString)
                }
            } else {
                const dateOptionAddedLast = this.getDateOptionAddedLast();
                if (dateOptionAddedLast != null) {
                    startTimeString = dateOptionAddedLast.timePeriodClass.startTimeString;
                    endTimeString = dateOptionAddedLast.timePeriodClass.endTimeString;
                }
            }
            return new TimePeriodClass(date, startTimeString, endTimeString)
        }
    }

    /**
     * @attention make sure all new option persist after calling this
     * @param option
     * @param dates
     */
    public repeatDateOption(option: OptionClass, dates: Date[]) {
        if (!option.isOfQuestionTypeDate) throw new Error('repeatOption not a date option' + JSON.stringify(option))
        dates.forEach((date) => {
            const newOption = option.deepCopy()
            newOption.timePeriodClass.setStartDate(date)
            this.add(newOption)
        });
    }

    /**
     *
     * @param date
     * @returns null if there is no option of the same date
     */
    private getLatestOptionOfSameDate(date: Date): OptionClass {
        let returnValue: OptionClass = null;
        this.forEach(option => { // forEach is iterating in sort order and jumps deleted options
            if (option.isOfQuestionTypeDate) {
                if (TimePeriodClass.areDatesEqual(option.timePeriodClass.startDateTime, date)) {
                    if (returnValue === null ||
                        option.timePeriodClass.startDateTime > returnValue.timePeriodClass.startDateTime // startDate actually does consider the time
                    ) {
                        returnValue = option
                    }
                }
            }
        })
        return returnValue
    }


    private getDateOptionAddedLast() {
        let returnValue: OptionClass = null;
        this.forEach(option => { // forEach is iterating in sort order and jumps deleted options
            if (option.isOfQuestionTypeDate) {
                if (returnValue === null || option.index > returnValue.index) {
                    returnValue = option
                }
            }
        })
        return returnValue
    }

    private getLatestOptionBeforeDate(date: Date): OptionClass {
        let returnValue: OptionClass = null;
        this.forEach(option => { // forEach is iterating in sort order and jumps deleted options
            if (option.isOfQuestionTypeDate) {
                if (option.timePeriodClass.startDateTime < date) {
                    if (returnValue === null || option.timePeriodClass.startDateTime > returnValue.timePeriodClass.startDateTime) {
                        returnValue = option
                    }
                }
            }
        })
        return returnValue
    }

    public isPreviousOfSameStartDate(optionClass: OptionClass): boolean {
        if (!optionClass.isOfQuestionTypeDate) { return false }
        const sortIndex = this.getSortIndex(optionClass.index)
        if (sortIndex === 0) { return false }
        const previousOptionIndex = this.sortMap[sortIndex - 1]
        const previousOption = this._optionClassArray[previousOptionIndex]
        if (!previousOption || !previousOption.isOfQuestionTypeDate) return false
        return previousOption.timePeriodClass.startDateTime.toDateString() === optionClass.timePeriodClass.startDateTime.toDateString()

    }

    public isNextOfSameStartDate(optionClass: OptionClass): boolean {
        if (!optionClass.isOfQuestionTypeDate) { return false }
        const sortIndex = this.getSortIndex(optionClass.index)
        if (sortIndex === 0) { return false }
        const nextOptionIndex = this.sortMap[sortIndex + 1]
        const nextOption = this._optionClassArray[nextOptionIndex]
        if (!nextOption || !nextOption.isOfQuestionTypeDate) return false
        return nextOption.timePeriodClass.startDateTime.toDateString() === optionClass.timePeriodClass.startDateTime.toDateString()
    }


    //
    public getParentOption(option: OptionClass): OptionClass | null {
        if (option.optionConfig.isParent) return null;
        const sortIndex = this.getSortIndex(option.index);
        for (let i = sortIndex - 1; i >= 0; i--) {
            const optionIndex = this.sortMap[i];
            const potentialParentOption = this._optionClassArray[optionIndex];
            if (potentialParentOption.optionConfig.isParent) {
                return potentialParentOption;
            }
        }
        return null;
    }

    get isParentInList(): boolean {
        let returnValue = false
        this.forEach(option => {
            if (option.optionConfig.isParent) returnValue = true
        })
        return returnValue

    }

}
