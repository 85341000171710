import { repairMissingIndexes } from "../../helper";
import { EssGenericListItem } from "../../shared/array-like-list/generic-list-item.class";
import cloneDeep from 'lodash/cloneDeep';
export enum ChoiceEnum {
    yes = 'YES',
    no = 'NO',
    mayBe = 'MAYBE',
    notSelected = 'NOT_SELECTED'
}
export interface SecondaryAnswerData {
    count?: number,
    bool?: boolean,
    text?: string,
    index?: number, /** for radio buttons , dropdown etc  */
}

export interface ChoiceData {
    choiceEnum: ChoiceEnum;
    count?: number; /**  not in use? */
    text?: string  /**  not in use? */
    secondaryAnswerArray?: SecondaryAnswerData[]
    adultsCount?: number; /** @deprictated: can be handled using the number answer */
    kidsCount?: number; /** @deprictated: can be handled using the number answer */
    adultVegetariansCount?: number; /** @deprictated: can be handled using the number answer */
    kidVegetariansCount?: number; /** @deprictated: can be handled using the number answer */
    comment?: string; /** @todo make it a seperate objectArray - a user may add various comments, it also needs a date with it*/
}
const secondaryAnswerDefault = { text: "" }

export class ChoiceClass extends EssGenericListItem<ChoiceData> {
    constructor(choice?: ChoiceEnum | ChoiceData) {
        super(ChoiceClass.convertToChoiceData(choice))
        this.initSecondaryAnswerArray(1, secondaryAnswerDefault)
        repairMissingIndexes(this._data.secondaryAnswerArray, {})
    }

    initSecondaryAnswerArray(length: number, defaultData?: SecondaryAnswerData) {
        if (!this._data.secondaryAnswerArray) this._data.secondaryAnswerArray = [];
        if (!defaultData) defaultData = secondaryAnswerDefault
        for (let index = this._data.secondaryAnswerArray.length; index < length; index++) {
            this._data.secondaryAnswerArray.push(cloneDeep(defaultData))
        }
    }

    addItemToSecondaryAnswerArray(defaultData?: SecondaryAnswerData) {
        this._data.secondaryAnswerArray.push({ ...defaultData })
    }

    get choiceEnum(): ChoiceEnum {
        return this._data.choiceEnum
    }

    set choiceEnum(value: ChoiceEnum) {
        this._data.choiceEnum = value
    }

    /**
     * @deprecated use .data instead
     */
    get persistenceData(): ChoiceData {
        return this.data
    }

    get secondaryAnswerArray(): SecondaryAnswerData[] {
        return this._data.secondaryAnswerArray
    }

    get adultsCount(): number {
        return this._data.adultsCount
    }

    set adultsCount(value: number) {
        this._data.adultsCount = value;
    }

    get kidsCount(): number {
        return this._data.kidsCount
    }

    set kidsCount(value: number) {
        this._data.kidsCount = value;
    }

    get adultVegetariansCount(): number {
        return this._data.adultVegetariansCount
    }

    set adultVegetariansCount(value: number) {
        this._data.adultVegetariansCount = value;
    }

    get kidVegetariansCount(): number {
        return this._data.kidVegetariansCount
    }

    set kidVegetariansCount(value: number) {
        this._data.kidVegetariansCount = value;
    }

    get comment(): string {
        return this._data.comment
    }

    set comment(value: string) {
        this._data.comment = value;
    }

    get isNo(): boolean {
        return ChoiceClass.isNo(this.choiceEnum)
    }

    get isYes(): boolean {
        if (this.choiceEnum === ChoiceEnum.yes) return true;
        return false
    }

    get isMaybe(): boolean {
        if (this.choiceEnum === ChoiceEnum.mayBe) return true;
        return false
    }

    private static convertToChoiceData(choice?: ChoiceEnum | ChoiceData | undefined): ChoiceData {
        if (!choice) {
            return { choiceEnum: ChoiceEnum.notSelected }
        }
        if (ChoiceClass.isOfTypeChoiceEnum(choice)) {
            return { choiceEnum: <ChoiceEnum>choice }
        }
        return <ChoiceData>choice
    }

    public static isOfTypeChoiceEnum(choice: ChoiceEnum | ChoiceData | undefined): boolean {
        return choice !== undefined && typeof choice === 'string'
    }

    public static isNo(pollChoice: ChoiceEnum): boolean {
        if (pollChoice === ChoiceEnum.yes || pollChoice === ChoiceEnum.mayBe) {
            return false
        }
        return true
    }

    public static isYes(pollChoice: ChoiceEnum): boolean {
        if (pollChoice === ChoiceEnum.yes) {
            return true
        }
        return false
    }

    public static isOfTypeChoiceData(choice: ChoiceEnum | ChoiceData | undefined): boolean {
        return choice !== undefined && typeof choice === 'object'
    }


}
