import { ChoiceClass, ChoiceData, ChoiceEnum } from "../vote/choice.class";
import { ChoiceList } from "./choice-list.class";
import cloneDeep from 'lodash/cloneDeep';
import assign from 'lodash/assign'

export interface VoteData {
    choicesArray?: (ChoiceEnum | ChoiceData)[];
    updatedAt?: any;
    createdAt?: any;
    receiveUpdates?: boolean;
    receiveUpdatesEmail?: string;
    userName?: string;
    userEmail?: string;
    userPhone?: string;
    userId?: string;
    choiceInvitation?: number;
    adultsCount?: number;
    kidsCount?: number;
    adultVegetariansCount?: number;
    kidVegetariansCount?: number;
    privateComment?: string,
    publicComment?: string,
    customFlag1?: string,
    customFlag2?: string,

}

export class VoteClass {
    private _voteData: VoteData = {};
    private _choiceList: ChoiceList

    constructor(voteData: VoteData, numperOfOptions: number) {
        if (voteData) {
            this._voteData = voteData
        }
        if (!voteData.choicesArray) voteData.choicesArray = [];
        this._choiceList = new ChoiceList().reInit(data => new ChoiceClass(data), voteData.choicesArray, numperOfOptions);
    }

    public initChoiceList(length: number) {
        this._choiceList.init(length, new ChoiceClass())
    }

    get choiceList(): ChoiceList {
        return this._choiceList
    }

    get persistenceData(): VoteData {
        const persistenceData: VoteData = cloneDeep(this._voteData)
        assign(persistenceData.choicesArray, this._choiceList.data)
        return persistenceData;
    }


    public getChoiceEnum(index: number): ChoiceEnum {
        if (index < this.choiceArrayLength) {
            return this.choiceList[index]?.choiceEnum
        } else {
            return ChoiceEnum.notSelected
        }
    }

    public getChoiceIsYes(index: number): boolean {
        const choiceClass = new ChoiceClass(this._voteData.choicesArray[index])
        return choiceClass.isYes
    }

    public get choiceArrayLength(): number {
        return this._voteData.choicesArray.length
    }



    /**
     *@deprictated use the ChoiceClass directly
     * @param index
     * @param choiceEnum
     */
    public setChoiceEnum(index: number, choiceEnum: ChoiceEnum) {
        this.initChoiceList(index);
        if (ChoiceClass.isOfTypeChoiceData(this._voteData.choicesArray[index])) {
            (<ChoiceData>this._voteData.choicesArray[index]).choiceEnum = choiceEnum
        } else {
            (<ChoiceEnum>this._voteData.choicesArray[index]) = choiceEnum
        }
    }



    get updatedAt(): any | undefined {
        return this._voteData.updatedAt;
    }
    set updatedAt(value: any | undefined) {
        this._voteData.updatedAt = value;
    }

    get createdAt(): any | undefined {
        return this._voteData.createdAt;
    }
    set createdAt(value: any | undefined) {
        this._voteData.createdAt = value;
    }

    get receiveUpdates(): boolean | undefined {
        return this._voteData.receiveUpdates;
    }
    set receiveUpdates(value: boolean | undefined) {
        this._voteData.receiveUpdates = value;
    }

    get receiveUpdatesEmail(): string | undefined {
        return this._voteData.receiveUpdatesEmail;
    }
    set receiveUpdatesEmail(value: string | undefined) {
        this._voteData.receiveUpdatesEmail = value;
    }

    get userName(): string | undefined {
        return this._voteData.userName;
    }
    set userName(value: string | undefined) {
        this._voteData.userName = value;
    }

    get userEmail(): string | undefined {
        return this._voteData.userEmail;
    }
    set userEmail(value: string | undefined) {
        this._voteData.userEmail = value;
    }

    get userPhone(): string | undefined {
        return this._voteData.userPhone;
    }
    set userPhone(value: string | undefined) {
        this._voteData.userPhone = value;
    }

    get userId(): string | undefined {
        return this._voteData.userId;
    }
    set userId(value: string | undefined) {
        this._voteData.userId = value;
    }

    get choiceInvitation(): number | undefined {
        return this._voteData.choiceInvitation;
    }
    set choiceInvitation(value: number | undefined) {
        this._voteData.choiceInvitation = value;
    }


    get peopleCount(): number {
        return this.adultsCount + this.kidsCount;
    }

    get vegetariansCount(): number {
        return this.adultVegetariansCount + this.kidVegetariansCount;
    }

    get adultsCount(): number {
        if (this._voteData.adultsCount === undefined) {
            return 0
        } else {
            return this._voteData.adultsCount;
        }
    }
    set adultsCount(value: number | undefined) {
        this._voteData.adultsCount = value;
    }

    get kidsCount(): number {
        if (this._voteData.kidsCount === undefined) {
            return 0
        } else {
            return this._voteData.kidsCount;
        }
    }
    set kidsCount(value: number | undefined) {
        this._voteData.kidsCount = value;
    }

    get adultVegetariansCount(): number {
        if (this._voteData.adultVegetariansCount === undefined) {
            return 0
        } else {
            return this._voteData.adultVegetariansCount;
        }
    }
    set adultVegetariansCount(value: number | undefined) {

        this._voteData.adultVegetariansCount = value;
    }

    get kidVegetariansCount(): number {
        if (this._voteData.kidVegetariansCount === undefined) {
            return 0
        } else {
            return this._voteData.kidVegetariansCount;
        }
    }

    set kidVegetariansCount(value: number | undefined) {
        this._voteData.kidVegetariansCount = value;
    }

    get privateComment(): string | undefined {
        return this._voteData.privateComment;
    }
    set privateComment(value: string | undefined) {
        this._voteData.privateComment = value;
    }


    get publicComment(): string | undefined {
        return this._voteData.publicComment;
    }
    set publicComment(value: string | undefined) {
        this._voteData.publicComment = value;
    }


    get customFlag1(): string | undefined {
        return this._voteData.customFlag1;
    }
    set customFlag1(value: string | undefined) {
        this._voteData.customFlag1 = value;
    }

    get customFlag2(): string | undefined {
        return this._voteData.customFlag2;
    }
    set customFlag2(value: string | undefined) {
        this._voteData.customFlag2 = value;
    }

    public cloneDeep(): VoteClass {
        return cloneDeep(this);
    }
}
