import { repairMissingIndexes, syncIndex } from "../../../helper";
import { MutltipleChoice, MutltipleChoiceData } from "./multiple-choice.class";
import cloneDeep from 'lodash/cloneDeep';


export interface MutltipleChoiceListPersistence {
    dataArray: MutltipleChoiceData[],
    sortMap: number[]
}
const dataDummy: MutltipleChoiceData =
{
    label: '',
    staticIndex: 0,
    isDeleted: false
};

export class MutltipleChoiceList implements Iterable<MutltipleChoice> {
    private _sortMap: number[] = []; // mapping the sort index to staticIndex, does not include deleted items
    private _itemArray: MutltipleChoice[] = [];

    constructor(dataArray?: MutltipleChoiceData[], sortMap?: number[]) {
        if (sortMap) this._sortMap = [...sortMap];
        const isArrayCorrupt = repairMissingIndexes(dataArray, dataDummy)
        const isIndexCorrupt = syncIndex(dataArray, 'staticIndex')
        this.populateItemArray(dataArray);
        if (this.isSortMapCorrupt || isArrayCorrupt || isIndexCorrupt) {
            this.resetSortMap();
        } else {
            this._sortMap = [...sortMap];
        }
    }

    get persistenceData(): MutltipleChoiceListPersistence {
        let dataArray: MutltipleChoiceData[] = []
        this._itemArray.forEach(item => {
            dataArray.push({ ...item.persistenceData })
        })
        let sortMap = this.sortMap
        return { dataArray: dataArray, sortMap: sortMap }
    }

    // Public Methods
    [Symbol.iterator](): Iterator<MutltipleChoice> {
        let index = 0;
        const data = this._itemArray;
        const sortMap = this._sortMap;
        return {
            next(): IteratorResult<MutltipleChoice> {
                if (index < sortMap.length) {
                    const value = data[sortMap[index]];
                    index++;
                    return { value, done: false };
                } else {
                    return { value: null, done: true };
                }
            }
        };
    }

    /** Loop in the sort order (without deleted items) */
    public forEach(callback: (item: MutltipleChoice, index: number) => void) {
        if (!this._sortMap) return;
        for (let i = 0; i < this._sortMap.length; i++) {
            const item = this._itemArray[this._sortMap[i]];
            if (item) {
                callback(item, i);
            }
        }
    }

    get last(): MutltipleChoice {
        const lastStaticIndex = this._sortMap[this._sortMap.length - 1]
        return this._itemArray[lastStaticIndex]
    }


    get lengthIncludingDeleted(): number {
        return this._itemArray.length

    }

    /**
     * An array of options that are not deleted
     */
    get notDeleted(): MutltipleChoice[] {
        return this._itemArray.filter(item => !item.isDeleted);
    }

    public getByStaticIndex(staticIndex: number): MutltipleChoice {
        return this._itemArray[staticIndex];
    }

    public getBySortIndex(sortIndex: number): MutltipleChoice {
        const staticIndex = this.getStaticIndex(sortIndex);
        return this._itemArray[staticIndex];
    }

    public getStaticIndex(sortIndex: number): number {
        if (this._sortMap[sortIndex] === null) throw new Error('!this._sortMap[sortIndex]');
        return this._sortMap[sortIndex];
    }

    public getSortIndex(staticIndex: number): number {
        return this._sortMap[staticIndex];
    }

    public add(item?: MutltipleChoice, sortIndextToBeAddedAt?: number) {
        if (!item) item = new MutltipleChoice(MutltipleChoiceList.getDummyData(this._itemArray.length));
        item.staticIndex = this._itemArray.length; // make sure the staticIndex is correct
        this._itemArray.push(item);
        if (sortIndextToBeAddedAt === undefined) {
            sortIndextToBeAddedAt = this.getIndexToBeAddedAt(item);
        }
        if (sortIndextToBeAddedAt > this._sortMap.length) {
            throw new Error("add item: indextToBeAddedAt > this.sortMap.length " + sortIndextToBeAddedAt + ", " + this._sortMap.length);
        }
        this.addToSortMap(item, sortIndextToBeAddedAt);
    }

    public delete(mcOption: MutltipleChoice) {
        if (mcOption) {
            mcOption.isDeleted = true;
            this.removeFromSortMap(mcOption);
        }
    }

    /**
     * Be careful, only the last element of itemArray should be deleted, otherwise indices will be messed up
     */
    private deleteHard(mcOption: MutltipleChoice): MutltipleChoice {
        if (mcOption) {
            this.removeFromSortMap(mcOption);
            return this._itemArray.splice(mcOption.staticIndex, 1)[0]; // return the deleted element
        }
    }

    /**
     * Delete the last option if it is empty and if it has not moved (hence the last in the itemArray and sortMap)
     * Deletion is NOT a soft delete
     */
    deleteEmptyLast() {
        const last = this.last
        if (last?.label === "") {
            // delete if the last element in the sort array is also the last one that was added
            console.warn('deleteEmptyLast', last.staticIndex, this._itemArray.length - 1)
            if (last.staticIndex === this._itemArray.length - 1) {
                this.deleteHard(last)
            }
        }
    }

    cloneDeep(): MutltipleChoiceList {
        let returnValue = cloneDeep(this)
        return returnValue;
    }

    get sortMap() {
        return this._sortMap
    }



    // Private Methods
    private populateItemArray(dataArray: MutltipleChoiceData[]) {
        dataArray?.forEach((optionData, index) => {
            this._itemArray.push(new MutltipleChoice(optionData));
        });
    }



    private static getDummyData(index: number): MutltipleChoiceData {
        const returnValue = { ...dataDummy }
        returnValue.staticIndex = index;
        return returnValue
    }

    private resetSortMap() {
        const itemArray = [...this._itemArray];
        // itemArray.sort(OptionClass.standardCompare)
        this._sortMap = [];
        itemArray.forEach((item) => {
            if (!item.isDeleted) {
                this._sortMap.push(item.staticIndex);
            }
        });
    }

    private get isSortMapCorrupt(): boolean {
        if (!this._sortMap) return true;
        if (this._sortMap.length == 0) return true;
        if (this._sortMap.length !== this.numberOfNotDeletedItems) return true;
        return false;
    }

    private get numberOfNotDeletedItems(): number {
        let returnValue = this._itemArray.length;
        this._itemArray.forEach(item => {
            if (item.isDeleted) returnValue--;
        });
        return returnValue;
    }

    private addToSortMap(item: MutltipleChoice, indexToBeAddedAt: number) {
        if (indexToBeAddedAt > this._sortMap.length + 1) { throw new Error('addToSortMap indexToBeAddedAt > this._sortMap.length + 1'); }
        const sortMapWithoutElement = this._sortMap;
        const newSortMap = sortMapWithoutElement.slice(0, indexToBeAddedAt)
            .concat([item.staticIndex])
            .concat(sortMapWithoutElement.slice(indexToBeAddedAt));
        this._sortMap = newSortMap;
    }

    private removeFromSortMap(item: MutltipleChoice) {
        const indexInSortMap = this._sortMap.indexOf(item.staticIndex)
        if (indexInSortMap !== -1) {
            this._sortMap.splice(indexInSortMap, 1)
        }
    }

    private getIndexToBeAddedAt(item: MutltipleChoice) {
        let indexToBeAddedAt = this._sortMap.length;
        return indexToBeAddedAt;
    }
}
