

export interface MutltipleChoiceData {
    label: string,
    staticIndex: number,
    isDeleted: boolean
};

export class MutltipleChoice {
    private _data: MutltipleChoiceData;

    constructor(multipleChoiceData?: MutltipleChoiceData) {
        if (!multipleChoiceData) {
            multipleChoiceData = { label: "", staticIndex: 0, isDeleted: false }
        }
        this._data = { ...multipleChoiceData }
    }

    get persistenceData(): MutltipleChoiceData {
        return this._data

    }
    get label(): string {
        return this._data.label;
    }

    set label(label) {
        this._data.label = label
    }

    get staticIndex(): number {
        return this._data.staticIndex;
    }
    set staticIndex(staticIndex: number) {
        this._data.staticIndex = staticIndex;
    }

    get isDeleted(): boolean {
        return this._data.isDeleted;
    }
    set isDeleted(isDeleted: boolean) {
        this._data.isDeleted = isDeleted;
    }
}
