import { TestClass, TestData } from "./test.class";
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export class TestList implements Iterable<TestClass>, ArrayLike<TestClass> {
    [n: number]: TestClass

    /**
     * Constructs an instance of the class, optionally initializing it with an array of `TestData` objects.
     * If a `dataArray` is provided, each element in the array is used to create a new instance of `TestClass`,
     * which is then assigned to the corresponding index of the instance being constructed.
     *
     * @param {TestData[]} [dataArray] - An optional array of `TestData` objects to initialize the instance.
     */
    constructor(dataArray?: TestData[]) {
        if (dataArray) {
            dataArray.forEach((data, index) => {
                this[index] = new TestClass(data);
            });
        }
    }

    /**
     * Adds one or more items to the end of the TestList.
     * @param {...TestClass[]} items - The items to add.
     * @returns {number} - The new length of the TestList.
     */
    public push(...items: TestClass[]): number {
        let length = this.length;
        for (const item of items) {
            this[length++] = item;
        }
        return length;
    }

    /**
     * Removes the last item from the TestList.
     * @returns {TestClass | undefined} - The removed item, or undefined if the list is empty.
     */
    public pop(): TestClass | undefined {
        const length = this.length;
        if (length === 0) return undefined;
        const lastItem = this[length - 1];
        delete this[length - 1];
        return lastItem;
    }

    /**
     * Creates a new array populated with the results of calling a provided function on every element in the TestList.
     * @template U
     * @param {(value: TestClass, index: number, array: TestClass[]) => U} callback - Function that produces an element of the new array.
     * @returns {U[]} - A new array with each element being the result of the callback function.
     */
    public map<U>(callback: (value: TestClass, index: number, array: TestClass[]) => U): U[] {
        const result: U[] = [];
        for (let i = 0; i < this.length; i++) {
            result.push(callback(this[i], i, Object.values(this)));
        }
        return result;
    }

    /**
     * Creates a new TestList with all elements that pass the test implemented by the provided function.
     * @param {(value: TestClass, index: number, array: TestClass[]) => boolean} callback - Function to test each element.
     * @returns {TestList} - A new TestList with the elements that pass the test.
     */
    public filter(callback: (value: TestClass, index: number, array: TestClass[]) => boolean): TestList {
        const result = new TestList();
        for (let i = 0; i < this.length; i++) {
            if (callback(this[i], i, Object.values(this))) {
                result.push(this[i]);
            }
        }
        return result;
    }

    /**
     * Returns the first element in the TestList that satisfies the provided testing function.
     * @param {(value: TestClass, index: number, array: TestClass[]) => boolean} callback - Function to test each element.
     * @returns {TestClass | undefined} - The first element that passes the test, or undefined if no elements pass.
     */
    public find(callback: (value: TestClass, index: number, array: TestClass[]) => boolean): TestClass | undefined {
        for (let i = 0; i < this.length; i++) {
            if (callback(this[i], i, Object.values(this))) {
                return this[i];
            }
        }
        return undefined;
    }

    /**
     * Initializes the TestList to a specified length by adding new instances of `TestClass`.
     * If the current length of the TestList is less than the specified length, new instances of `TestClass`
     * are created and added to the end of the TestList until it reaches the specified length.
     * Does not overwrite existing item in TestList
     *
     * @param {number} length - The desired length of the TestList.
     */
    init(length: number, defaultObject?: TestClass, defaultObjectManipulator?: (obj: TestClass, index: number) => void) {
        for (let i = this.length; i < length; i++) {
            const newObj = defaultObject ? new TestClass(defaultObject) : new TestClass();
            if (defaultObjectManipulator) {
                defaultObjectManipulator(newObj, i);
            }
            this.push(newObj);
        }
    }

    /**
     * Returns an array of `TestData` objects representing the data of each `TestClass` instance in the TestList.
     * @returns {TestData[]} - An array of `TestData` objects.
     */
    get data(): TestData[] {
        return Object.values(this).map((testClass) => testClass.data);
    }

    /**
     * Gets the length of the TestList.
     * @returns {number} - The number of elements in the TestList.
     */
    get length() {
        return Object.values(this).length;
    }

    cloneDeep(): TestList {
        return cloneDeep(this);
    }

    isEqual(other: TestList): boolean {
        return isEqual(this, other);
    }

    /**
     * Returns an iterator for the TestList.
     * @returns {Iterator<TestClass>} - An iterator for the TestList.
     */
    [Symbol.iterator](): Iterator<TestClass> {
        let index = 0;
        const itemArray = Object.values(this);
        return {
            next(): IteratorResult<TestClass> {
                if (index < itemArray.length) {
                    const value = itemArray[index];
                    index++;
                    return { value, done: false };
                } else {
                    return { value: null, done: true };
                }
            }
        };
    }
}

