import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ChoiceEnum, OptionClass, SecondaryAnswerData, VoteClass } from 'whocan-lib';
import { OptionsService } from '../shared/options/options.service';
import { VotesService } from '../features/vote/votes.service';
import { HeaderService } from '../shared/poll-header/header.service';
import { TimePeriodData } from 'whocan-lib';
import { AuthService } from '../../user/auth.service';
import { TemplateService } from './poll-templates/template.service';

export interface AnswersPerOptionPerUser {
    userName: string,
    userId: string,
    sumPerUser: number,
    answerArray?: { textAnswer?: string, count?: number }[]
}

@Injectable({
    providedIn: 'root'
})
/**
 *For getting derrivated attributes like yesVoters, participants, maxIsReached
* The data is not hold directly here but in the unlerlying OptionsService, VotesService etc
 */
export class PollResultsService {
    constructor(
        private logger: LoggerService,
        private votesService: VotesService,
        private headerService: HeaderService,
        public optionsService: OptionsService,
        private authService: AuthService,
        private templateService: TemplateService
    ) {
    }

    public getYesVoters(optionIndex: number): string[] {
        const returnValue: string[] = [];
        this.votesService?.voteClassList.forEach((vote) => {
            const choiceList = vote.choiceList
            if (choiceList) {
                if (choiceList[optionIndex]?.isYes) {
                    returnValue.push(vote.userName);
                }
            }
        });
        return returnValue;
    }

    public getNoVoters(optionIndex: number): string[] {
        const returnValue: string[] = [];
        this.votesService?.voteClassList.forEach((vote) => {
            const choiceList = vote.choiceList
            if (choiceList) {
                if (!choiceList[optionIndex] ||
                    choiceList[optionIndex]?.isNo ||
                    choiceList[optionIndex].choiceEnum === ChoiceEnum.notSelected
                ) {
                    returnValue.push(vote.userName);
                }
            }
        });
        return returnValue;
    }

    public getPositiveVoters(optionIndex: number): string[] {
        const returnValue: string[] = [];
        this.votesService?.voteClassList.forEach((vote, voteIndex) => {
            const choiceList = vote.choiceList
            if (choiceList) {
                if (choiceList[optionIndex]?.isYes ||
                    choiceList[optionIndex]?.isMaybe
                ) {

                    returnValue.push(this.getUserName(vote));
                }
            }
        });
        return returnValue;
    }

    public getRegisteredPeopleAnswers(optionIndex: number): { userName: string, count: number }[] {
        const returnValue: { userName: string, count: number }[] = [];
        this.votesService?.voteClassList.forEach((vote, voteIndex) => {
            if (vote.choiceList) {
                const answer: { userName: string, count: number } = {
                    userName: this.getUserName(vote),
                    count: 0,
                }
                const choice = vote.choiceList[optionIndex]
                if (choice?.isYes && choice?.adultsCount) {
                    answer.count = choice?.adultsCount
                    returnValue.push(answer)
                }
            }
        });
        return returnValue;
    }

    public getNumberOfParticipants(option: OptionClass): number {
        if (option.optionConfig.pimaryAnswer === 'willJoin') {
            return this.getRegisteredPeopleAnswersSum(option.index)
        } else {
            return this.getQuantityOfYesVoters(option.index)
        }
    }

    public getRegisteredPeopleAnswersSum(optionIndex: number): number {
        let returnValue = 0;
        this.getRegisteredPeopleAnswers(optionIndex).forEach(answer => {
            returnValue += answer.count
        })
        return returnValue
    }

    private getUserName(vote: VoteClass): string {
        if (vote.userName) {
            return vote.userName
        } else if (vote.userId === this.authService.uid) {
            return this.templateService.getLabel('vote', 'you')
        }
        return this.templateService.getLabel('vote', 'noName')
    }

    public getTextAndNumberAnswers(optionIndex: number): AnswersPerOptionPerUser[] {
        const returnValue: AnswersPerOptionPerUser[] = [];
        this.votesService?.voteClassList.forEach((vote, voteIndex) => {
            if (vote.choiceList) {
                let addToAnswers: boolean = false
                const textAndNumberAnswer: AnswersPerOptionPerUser = {
                    userName: this.getUserName(vote),
                    userId: vote.userId,
                    sumPerUser: 0,
                    answerArray: []
                }
                vote.choiceList[optionIndex]?.secondaryAnswerArray?.forEach((answer) => {
                    if (
                        answer.text
                    ) {
                        const count = answer.count ? answer.count : 0
                        textAndNumberAnswer.answerArray.push({
                            textAnswer: answer.text,
                            count: count
                        });
                        textAndNumberAnswer.sumPerUser += count;
                        addToAnswers = true
                    }
                })
                if (addToAnswers) {
                    returnValue.push(textAndNumberAnswer)
                }
            }
        });
        return returnValue;
    }

    public getNumberAnswers(optionIndex: number): AnswersPerOptionPerUser[] {
        const returnValue: AnswersPerOptionPerUser[] = [];
        this.votesService?.voteClassList.forEach((vote, voteIndex) => {
            if (vote.choiceList) {
                let addToAnswers: boolean = false
                const textAndNumberAnswer: AnswersPerOptionPerUser = {
                    userName: this.getUserName(vote),
                    userId: vote.userId,
                    sumPerUser: 0,
                    answerArray: []
                }
                vote.choiceList[optionIndex]?.secondaryAnswerArray?.forEach((answer) => {
                    if (
                        answer.count !== undefined && answer.count !== null
                    ) {
                        textAndNumberAnswer.answerArray.push({
                            textAnswer: answer.text,
                            count: answer.count
                        });
                        textAndNumberAnswer.sumPerUser += answer.count;
                        addToAnswers = true
                    }
                })
                if (addToAnswers) {
                    returnValue.push(textAndNumberAnswer)
                }
            }
        });
        return returnValue;
    }

    getSumOfNumberAnswers(optionIndex: number): number {
        let returnValue: number = 0;
        this.votesService?.voteClassList.forEach((vote, voteIndex) => {
            const choiceList = vote.choiceList
            if (choiceList) {
                choiceList[optionIndex]?.secondaryAnswerArray?.forEach((answer) => {
                    if (answer.count) {
                        returnValue += answer.count
                    }
                })
            }
        });
        return returnValue;
    }

    getAverageOfNumberAnswers(optionIndex: number): number {
        let sum: number = 0;
        let numberOfVoters: number = 0
        this.votesService?.voteClassList.forEach((vote, voteIndex) => {
            const choiceList = vote.choiceList
            if (choiceList) {
                choiceList[optionIndex]?.secondaryAnswerArray.forEach((answer) => {
                    if (answer.count) {
                        numberOfVoters += 1
                        sum += answer?.count
                    }
                })
            }
        });
        if (numberOfVoters > 0) {
            return parseFloat((sum / numberOfVoters).toFixed(2));
        } else {
            return 0
        }
    }

    private getQuantityOfNoVoters(optionIndex: number): number {
        return this.getNoVoters(optionIndex).length
    }

    public getQuantityOfYesVoters(optionIndex: number): number {
        return this.getYesVoters(optionIndex).length
    }

    public getParticipants(): string[] {
        const participants: string[] = [];
        this.votesService.voteClassList.forEach(element => {
            participants.push(element.userName);
        });
        return participants;
    }

    public getMaxNumberOfEntries(optionIndex: number): number {
        let maxNumberOfEntries: number = this.optionsService.optionList.getByOptionIndex(optionIndex)?.maxNumberOfEntries;
        if (!maxNumberOfEntries) {// check if we have a max on the poll level
            maxNumberOfEntries = this.headerService.headerClass.limitPerOption;
        }
        return maxNumberOfEntries;
    }

    public isMaxNumberSet(optionIndex): boolean {
        if (this.getMaxNumberOfEntries(optionIndex) > 0) {
            return true;
        }
        return false;
    }

    public secondaryAnswerMaxReached(option: OptionClass): boolean {
        const sum = this.getSumOfNumberAnswers(option.index)
        const maxNumberOfEntries = option.secondaryAnswerMax
        if (!maxNumberOfEntries) return false
        return sum >= maxNumberOfEntries
    }

    public maxParticipantsReached(option: OptionClass, usersVoteIndex: number): boolean {
        if (option.optionConfig.pimaryAnswer === 'willJoin') return this.maxRegisteredPeopleReached(option, usersVoteIndex)
        return this.maxYesVotersReached(option, usersVoteIndex)

    }

    private maxYesVotersReached(option: OptionClass, usersVoteIndex: number): boolean {
        const numberOfParticipants = this.getQuantityOfYesVoters(option.index);
        let userHimself = 0;
        if (usersVoteIndex !== -1) {
            const choiceList = this.votesService.voteClassList[usersVoteIndex]?.choiceList
            if (choiceList) {
                const thisUsersChoice = choiceList[option.index];
                if (thisUsersChoice && thisUsersChoice.isYes) {
                    userHimself = 1;
                }
            }
        }
        const maxNumberOfEntries: number = this.getMaxNumberOfEntries(option.index);
        // this.logger.warn('maxNumberOfEntries numberOfParticipants, userHimself', maxNumberOfEntries, numberOfParticipants, userHimself)
        if (maxNumberOfEntries == null || maxNumberOfEntries === 0
            || numberOfParticipants == null || numberOfParticipants === 0
            || maxNumberOfEntries > (numberOfParticipants - userHimself)) {
            return false;
        }
        return true;
    }

    private maxRegisteredPeopleReached(option: OptionClass, usersVoteIndex: number): boolean {
        //   return this.getRegisteredPeopleAnswersSum(option.index) >= this.getMaxNumberOfEntries(option.index)
        const numberOfParticipants = this.getRegisteredPeopleAnswersSum(option.index);
        let userHimself = 0;
        if (usersVoteIndex !== -1) {
            const choiceList = this.votesService.voteClassList[usersVoteIndex]?.choiceList
            if (choiceList) {
                const thisUsersChoice = choiceList[option.index];
                if (thisUsersChoice && thisUsersChoice.isYes) {
                    userHimself = thisUsersChoice?.adultsCount;
                }
            }
        }
        const maxNumberOfEntries: number = this.getMaxNumberOfEntries(option.index);
        // this.logger.warn('maxNumberOfEntries numberOfParticipants, userHimself', maxNumberOfEntries, numberOfParticipants, userHimself)
        if (maxNumberOfEntries == null || maxNumberOfEntries === 0
            || numberOfParticipants == null || numberOfParticipants === 0
            || maxNumberOfEntries > (numberOfParticipants - userHimself)) {
            return false;
        }
        return true;
    }

    get pollParticipantsCount(): number {
        return this.votesService.voteClassList.length
    }

    get pollPartipantsCountSinceLastWeek(): number {
        let returnValue = 0
        let oneWeekAgo = new Date();
        oneWeekAgo.setDate(new Date().getDate() - 7);
        this.votesService.voteClassList.forEach(vote => {
            const createdAt = new Date(vote.createdAt)
            if (createdAt > oneWeekAgo) {
                returnValue++
            }
        })
        return returnValue
    }

    get daysSincePollCreation() {
        const createdAt = new Date(this.headerService.headerClass.createdAt).getTime();
        const today = new Date().getTime();
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const daysSinceCreation = Math.floor((today - createdAt) / millisecondsPerDay);
        return daysSinceCreation;
    }

    get bestDateOptions(): { timePeriod: TimePeriodData, yes: number, no: number }[] {
        const numberOfBestOptions = 3
        let returnValue: { timePeriod: TimePeriodData, yes: number, no: number }[] = []
        this.optionsService.dateOptions.forEach(option => {
            returnValue.push({
                timePeriod: option.timePeriodClass.timePeriodData,
                yes: this.getQuantityOfYesVoters(option.index),
                no: this.getQuantityOfNoVoters(option.index)
            })
        })
        returnValue.sort((a, b) => b.yes - a.yes)
        returnValue = returnValue.slice(0, numberOfBestOptions)
        return returnValue
    }

    get bestTextOptions(): { label: string, yes: number, no: number }[] {
        const numberOfBestOptions = 3
        let returnValue: { label: string, yes: number, no: number }[] = []
        this.optionsService.optionList.forEach(option => {
            if (option.optionConfig.isOfTypeText) {
                returnValue.push({
                    label: option.label,
                    yes: this.getQuantityOfYesVoters(option.index),
                    no: this.getQuantityOfNoVoters(option.index)
                })

            }
        })
        returnValue = returnValue.filter(option => option.yes > 0);
        returnValue.sort((a, b) => b.yes - a.yes)
        returnValue = returnValue.slice(0, numberOfBestOptions)
        return returnValue
    }


    getCheckBoxAnswer(secondaryAnswer: SecondaryAnswerData): string {

        if (secondaryAnswer?.bool) {
            return 'getCheckBoxAnswer: ' + secondaryAnswer?.bool

        }
    }

}
